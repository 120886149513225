import { render, staticRenderFns } from "./ViewAttach.vue?vue&type=template&id=2e876636&scoped=true&"
import script from "./ViewAttach.vue?vue&type=script&lang=js&"
export * from "./ViewAttach.vue?vue&type=script&lang=js&"
import style0 from "./ViewAttach.vue?vue&type=style&index=0&id=2e876636&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e876636",
  null
  
)

export default component.exports