<template>
    <div class="page-content">
        <div class="grid">
            <div class="page-list-body">
                <div class="row no-gutters page-list-header">
                    <div class="col l-6 m-6 c-12">
                        <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Phiếu mượn vật tư: {{entity.exportCode}}</span></h4>
                    </div>
                    <div class="col l-6 m-6 c-12">
                    </div>
                </div>
                <div class="form-body">
                    <div class="wrapper">
                        <div class="row no-gutters">
                            <div class="col l-12 m-12 c-12">
                                <div v-for="item in entity.files" :key="item.id">
                                    <img :src="item.fullPath" class="image-item" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
    import storeExportService from '../../../api/storeExportService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';

    export default ({
        metaInfo: {
            title: 'Phiếu mượn vật tư'
        },
        data() {
            return {
               title: 'Phiếu mượn vật tư',
               id: 0,
               entity: {},
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
                this.getById();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            getById(){
                this.setLoading(true);
                storeExportService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
    })

</script>

<style scoped>
    .wrapper {
        margin: 0 auto;
        width: 1000px;
        padding-top: 20px;
        padding-bottom: 30px;
    }

    .image-item {
        width: 100% !important;
        height: auto !important;
        margin-bottom: 20px;
    }
</style>
